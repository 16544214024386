/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

import "@fontsource/jost/latin-400.css"; /* normal */
import "@fontsource/jost/latin-500.css"; /* semibold */
import "@fontsource/jost/latin-600.css"; /* needed for font-weight animation */
import "@fontsource/jost/latin-700.css"; /* bold */

/* our styles */
import "stylesheets/public.scss";

/* turbo and stimulus */
import "@hotwired/turbo-rails"
Turbo.setProgressBarDelay(100);

import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

const Stimulus = Application.start();
const context = require.context("../src/controllers/public", true, /\.js$/);
Stimulus.load(definitionsFromContext(context));

import { Modal } from "bootstrap";

document.addEventListener('turbo:load', startApp)

function startApp(event) {
  import("../src/public/cookie-consent-config.js").then((module) => {
    window.cookieConsentForm = module.default();
  });

  import("../src/public/external-links.js").then((module) => {
    module.default.init();
  });

  import("../src/public/buy").then((module) => {
    module.default.init();
  });

  import("../src/public/newsletter-promo.js").then((module) => {
    module.default.init(Modal);
  });
}
