import { Controller } from "@hotwired/stimulus"
import { Tooltip } from "bootstrap";

export default class extends Controller {

  connect() {
    const tooltip = new Tooltip(this.element);
    if (this.element.tagName == "BUTTON") {
      this.element.addEventListener("click", event => tooltip.dispose());
    } else {
      this.element.addEventListener("click", event => tooltip.hide());
    }
  }

}
